import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/container";
import PropTypes from "prop-types";
import GutenbergBlocks from "../components/gutenberg-blocks";
import Breadcrumbs from "../components/breadcrumbs";

const Articles = ({
  data: {
    wpPage: { excerpt, seo, title, blocks },
  },
}) => {
  return (
    <Layout>
      <SEO
        title={seo?.title || title}
        description={seo?.metaDesc || excerpt}
        breadcrumbs={seo?.breadcrumbs}
      />
      <Container maxWidth="md" disableAnimation disableMargin>
        <Breadcrumbs breadcrumbList={seo?.breadcrumbs} />
        {blocks?.[0]?.name !== "mev/hero" &&
          blocks?.[0]?.name !== "mev/superhero" &&
          blocks?.[0]?.name !== "core/columns" && (
            <h1 style={{ textAlign: "left" }}>{title}</h1>
          )}
      </Container>
      <GutenbergBlocks blocks={blocks} />
    </Layout>
  );
};

Articles.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Articles;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      blocks {
        ...GutenbergBlocks
      }
      title
      excerpt
      uri
      isFrontPage
      seo {
        title
        breadcrumbs {
          text
          url
        }
        metaDesc
      }
    }
  }
`;
